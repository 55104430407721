<template>
  <div
    ref="chatsContainer"
    :class="{ 'is-mobile': isMobile }"
  >
    <h2 class="title is-3">Your chats</h2>
    <transition
      name="fade"
      mode="out-in"
    >
      <div
        v-if="isLoading"
        key="loading"
        class="has-padding has-text-centered"
      >
        <img
          src="@/assets/img/s2m-loader-big.gif"
          alt="loading..."
        />
      </div>
      <div
        v-else
        key="ready"
        class="chats-wrapper"
      >
        <div
          v-show="!isMobile || (isMobile && !chatDetail)"
          class="list-wrapper chats-column"
          :class="{ 'is-mobile': isMobile }"
        >
          <chat-item
            v-for="chat in sortedChats"
            :key="chat.Id"
            class="chat has-padding-right"
            :chat="chat"
            :maxCharacters="10"
          ></chat-item>
        </div>

        <!-- CHAT DETAIL -->
        <div
          class="message-window-wrapper chat-column has-background-white"
          :class="{
              'is-hidden-mobile': isMobile && !chatDetail,
              'is-hidden-tablet': isMobile && chatDetail
            }"
        >
          <transition
            name="slide-fade"
            mode="out-in"
          >
            <div
              v-if="!chatDetail && !isMobile"
              key="notSelected"
              :class="{ 'has-padding-left': !isMobile }"
            >
              <div class="notification has-icon is-info">
                <span class="icon notification-icon fa-lg">
                  <i class="fas fa-info-circle"></i>
                </span>
                <strong>No chat selected. Click on a chat from the list.</strong>
              </div>
            </div>
            <div
              v-if="chatDetail"
              key="selectedChat"
              :class="{ 'has-padding-left': !isMobile }"
              class="chatMessageWrapper"
            >
              <div
                v-if="isMobile"
                class="action-row has-margin-bottom"
              >
                <div class="level">
                  <div class="level-left">
                    <a
                      @click="backToChats"
                      class="button has-margin-right"
                    >
                      <span class="icon">
                        <i class="fas fa-bars"></i>
                      </span>
                    </a>
                  </div>
                  <div class="level-right"></div>
                </div>
                <hr />
              </div>
              <chat-detail
                :chat="chatDetail"
                :key="'cd'+chatDetail.Id"
                :isMobile="isMobile"
                class="detail-wrapper"
              ></chat-detail>
            </div>
          </transition>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import ChatDetail from '@/components/ChatDetail'

export default {
  components: {
    ChatDetail
  },

  data() {
    return {
      isLoading: false,
      isMobile: false
    }
  },

  computed: {
    ...mapState('chatStore', ['chats', 'selectedChat']),

    chatDetail() {
      return this.selectedChat
    },

    sortedChats() {
      if (this.chats.length === 0) { return }
      let _chats = this.chats
      return _chats.sort(
        (a, b) => parseFloat(b.LastActivity) - parseFloat(a.LastActivity)
      )
    }
  },

  created() {
    this.getChats()
  },

  mounted() {
    this.isMobile =
      this.$refs.chatsContainer.offsetWidth &&
      this.$refs.chatsContainer.offsetWidth < 700

    window.addEventListener('resize', this.resizeHandler)
    this.resizeHandler()
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.resizeHandler)
    this.setSelectedChat(null)
    this.$store.commit('setSelectedPublicProfile', {
      profileId: 0,
      view: 'detail'
    })
  },

  methods: {
    ...mapActions('chatStore', ['getProfileChats']),
    ...mapMutations('chatStore', ['setSelectedChat']),

    resizeHandler() {
      let self = this

      self.isMobile =
        self.$refs.chatsContainer.offsetWidth &&
        self.$refs.chatsContainer.offsetWidth < 700
    },

    backToChats() {
      this.setSelectedChat(null)
    },

    async getChats() {
      this.isLoading = true
      try {
        await this.getProfileChats()
      }
      catch (e) {
        console.log(e)
      }
      finally {
        this.isLoading = false
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.chats-wrapper {
  align-items: stretch;
  display: flex;
  position: relative;
  justify-content: flex-start;
  height: 74vh;
  width: 100%;

  .list-wrapper {
    border-right: 1px solid $grey-light;
    height: 100%;
    overflow: auto;
    width: 30%;

    &.is-mobile {
      border-right: 0px solid $grey-light;
      width: 100%;
    }

    .media {
      cursor: pointer;
      word-break: break-word;
    }
    .button-more {
      width: 100%;
    }
  }

  .message-window-wrapper {
    height: 100%;
    &.is-hidden-tablet {
      position: absolute;
      top: $gap;
      right: 0;
      bottom: $gap;
      left: 0;
      width: auto;
    }

    width: 70%;
    .chatMessageWrapper {
      display: flex;
      flex-direction: column;
      height: 100%;
      .action-row {
        flex-grow: 0;
        flex-shrink: 0;
      }
      .detail-wrapper {
        flex-grow: 1;
      }
    }
  }
}
</style>
